@charset "utf-8";
#GeneralPrincipalOutBox{
    margin: 0;
    padding: 0;
    display: block;
    width: 100%;
    background-color: #F7F8FA;
}
#GeneralOutBox #OutboxTitle{
    margin: 0;
    padding: 0;
    display: block;
    width: 100%;
    color: #153768;
}
#GeneralOutBox .OutBoxInput{
    margin: 0;
    padding: 0;
    display: block;
    background-color: transparent;
    border: none;
    color: #444444;
    transition: .3s;
}
#GeneralOutBox .OutBoxInput_Principal{
    border-bottom: 1px solid #cccccc;
}
#GeneralOutBox .OutBoxInput_Principal:hover,
#GeneralOutBox .OutBoxInput_Principal:focus{
    border-bottom: 1px solid #153768;
}
#GeneralOutBox #OutBoxInput_Message{
    border: 1px solid #cccccc;
}
#GeneralOutBox #OutBoxInput_Message:hover,
#GeneralOutBox #OutBoxInput_Message:focus{
    border: 1px solid #153768;
}
#GeneralOutBox #OutBoxInput_Button{
    background-color: #153768;
}
#GeneralOutBox #OutBoxInput_Button:hover,
#GeneralOutBox #OutBoxInput_Button:focus{
    background-color: #00C7D6;
}
@media screen and (min-width: 701px) {
    #GeneralPrincipalOutBox{
        padding: 6vh 0;
    }
    #GeneralOutBox #OutboxTitle{
        font-size: 2.2em;
        margin-bottom: 1vh;
    }
    #GeneralOutBox .OutBoxInput{
        padding-bottom: 1px;
        margin: 1.5vh 0;
    }
    #GeneralOutBox .OutBoxInput_Principal{
        font-size: 1.1em;
        width: 450px;
    }
    #GeneralOutBox #OutBoxInput_Message{
        font-size: 1em;
        width: 90%;
        padding: 10px 5px;
        height: 10vh;
        margin-top: 4vh;
    }
    #GeneralOutBox #OutBoxInput_Button{
        font-size: 1.5em;
    }
}
@media screen and (max-width: 700px) {
    #GeneralOutBox{
        margin: 2vh auto;
    }
	#GeneralOutBox #OutboxTitle{
        font-size: 2.6em;
        margin-bottom: 1vh;
    }
    #GeneralOutBox .OutBoxInput{
        padding-bottom: 1px;
        margin: 1.5vh 0;
    }
    #GeneralOutBox .OutBoxInput_Principal{
        font-size: 1.1em;
        width: 90%;
    }
    #GeneralOutBox #OutBoxInput_Message{
        font-size: 1em;
        width: 90%;
        padding: 10px 5px;
        height: 10vh;
        margin-top: 4vh;
    }
    #GeneralOutBox #OutBoxInput_Button{
        font-size: 1.5em;
    }
}
